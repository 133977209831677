import {createGlobalStyle} from 'styled-components';
import {palette, font} from 'styled-theme';
import 'antd/dist/antd.less';
import BeVietnamProBold from '../fonts/BeVietnamPro-Bold.ttf';
import BeVietnamProMedium from '../fonts/BeVietnamPro-Medium.ttf';
import BeVietnamProRegular from '../fonts/BeVietnamPro-Regular.ttf';
import BeVietnamProSemiBold from '../fonts/BeVietnamPro-SemiBold.ttf';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'BeVietnamPro';
    src: url(${BeVietnamProRegular}) format("truetype");
    font-style: normal;
    font-weight: normal;
  }

  @font-face {
    font-family: 'BeVietnamPro';
    src: url(${BeVietnamProMedium}) format("truetype");
    font-style: normal;
    font-weight: 500;
  }

  @font-face {
    font-family: 'BeVietnamPro';
    src: url(${BeVietnamProSemiBold}) format("truetype");
    font-style: normal;
    font-weight: 600;
  }

  @font-face {
    font-family: 'BeVietnamPro';
    src: url(${BeVietnamProBold}) format("truetype");
    font-style: normal;
    font-weight: bold;
  }
  * {
    font-family: 'BeVietnamPro', sans-serif;
  }
  .ant-btn.common-btn {
    color: #FFFFFF;
    background: #007BFF;
    border-color: #007BFF;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15), 0px 0px 8px rgba(0, 0, 0, 0.07);
    &:hover {
      color: #FFFFFF;
      border-color: #007BFF;
      background: #007BFF;
    }
    &:focus {
      color: #FFFFFF;
      border-color: #007BFF;
      background: #007BFF;
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    font-size: 32px;
    font-weight: 700;
    color: #fff;
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
  }

  .ant-row.ant-form-item {
    margin-bottom: 12px;
  }

  .has-success.has-feedback {
    .ant-select {
      .ant-select-selection {
        .ant-select-selection__rendered {
          .ant-select-selection__placeholder {
            display: none !important;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for project category menu [ScrumBoard]
  /*-----------------------------------------------*/
  .project-category {
    .ant-select-dropdown-menu {
      .ant-select-dropdown-menu-item {
        padding: 8px 12px;
        color: #000000;
        font-family: 'BeVietnamPro';
        font-weight: 400;
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for project menu [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-dropdown {
    &.project-menu {
      width: 280px;
      top: 133px !important;
      
      .ant-dropdown-menu {
        padding: 0;
        overflow: hidden;

        .ant-dropdown-menu-item {
          min-height: 54px;
          line-height: auto;
          display: flex;
          align-items: center;
          padding: 10px 20px;

          &:first-child {
            padding: 0;
            border-bottom: 1px solid #f4f6fd;

            &:hover,
            &:focus {
              background-color: #ffffff;
            }
          }

          &:hover,
          &:focus {
            background-color: #F3F5FD;
          }

          &:last-child {
            background-color: #E6EAF8;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for popover [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-popover {
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      .ant-checkbox-group-item {
        margin: 5px 0;
        span {
          font-size: 14px;
          color: #788195;
          text-transform: capitalize;
        }
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for modal [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-modal-wrap {
    .ant-modal {
      .ant-modal-content {
        .ant-modal-body {
          .render-form-wrapper {
            padding: 10px;
            h2 {
              margin: 0;
            }
            form {
              padding: 15px 0 3px;
              .field-container {
                margin-bottom: 26px;
              }
            }
          }
        }
      }
    }
  }


/*-----------------------------------------------*/ 
  // style form previous GlobalStyles
  /*-----------------------------------------------*/

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #f8f8f8!important;
}

font-family: ${font('primary', 0)};

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
img,
svg {
  &::selection {
    background: ${palette('primary', 0)};
    color: #fff;
  }
}

.ant-row:not(.ant-form-item) {
  ${
    '' /* margin-left: -8px;
  margin-right: -8px; */
  };
  &:before,
  &:after {
    display: none;
  }
}

.ant-row > div {
  padding: 0;
}

.isoLeftRightComponent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.isoCenterComponent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.ant-input-search-icon::before {
  display: none;
}

// scroll bar custom

.scrollbar-custom::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 3px;
}
.scrollbar-custom::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.2);
}

// hide arrows from Input number

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}

span.ant-input-affix-wrapper {
  border-color: #d9d9d9;
  box-shadow: none;

  :hover {
    border-color: #d9d9d9;
  }
}

.ant-btn-circle img {
  vertical-align: -3px;
}
.ant-divider.ant-divider-horizontal {
  margin: 16px 0;
}
thead.ant-table-thead > tr > th {
  background: #F5F6F9;
  padding: 14px 16px;
}
tbody.ant-table-tbody > tr > td {
  padding-top: 7px; 
  padding-bottom: 7px;
  font-size: 13px;
}

// custom tooltip
.ant-tooltip-content .ant-tooltip-inner {
  font-size: 13px;
  background: #24253D;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 12%), 0px 6px 16px rgb(0 0 0 / 8%), 0px 9px 28px rgb(0 0 0 / 5%);
  border-radius: 4px;
  padding: 10px;
}

.ant-table.ant-table-empty .ant-table-tbody > tr.ant-table-placeholder .ant-table-expanded-row-fixed {
  background: #fff !important;
}

//
.operation-icon {
  font-size:20px;
  cursor: pointer;
}

// hide '*' in required label 

/* div.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none
} */

// ellipsis text

.ellipsisText {
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

// 

input.ant-input[disabled] {
  color:#24253D;
  background-color: #fff;
  cursor: default;
}

.clickable .ant-table-tbody > tr > td {
  cursor: pointer
}

textarea.ant-input {
  resize: none;
}

.ant-checkbox .ant-checkbox-inner {
  border-radius: 2px;
  color: rgba(0,0,0,0.34)
}

.ion-icon {
  font-size: 20px;
}

@keyframes blink {
  50% {
    opacity: 0.1;
  }
}
@-webkit-keyframes blink {
  50% {
    opacity: 0.1;
  }
}
.blink {
  animation: blink 1.5s steps(1,start) infinite;
  -webkit-animation: blink 1.5s steps(1,start) infinite;
}

@keyframes blinking_123 {
  0% {
    box-shadow: 0 0 0 2px #fff,0 0 0 4px #C5281C;
  }
  50% {
      box-shadow: 0 0 0 2px #fff,0 0 0 4px #d20f1c;
  }
  100% {
      box-shadow: 0 0 0 2px #fff,0 0 0 4px #C5281C;
  }
}
@-webkit-keyframes blinking_123 {
  0% {
    box-shadow: 0 0 0 2px #fff,0 0 0 4px #C5281C;
  }
  50% {
      box-shadow: 0 0 0 2px #fff,0 0 0 4px #d20f1c;
  }
  100% {
      box-shadow: 0 0 0 2px #fff,0 0 0 4px #C5281C;
  }
}

button.blinking {
  box-shadow: 0 0 0 2px #fff,0 0 0 4px #C5281C;
  animation-name: blinking_123;
  animation-duration: 1.75s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
}

// remove box-shadow when focus,hover

/* input.ant-input {
  box-shadow: none;
  border-color: #d9d9d9;

  :focus,:hover {
    box-shadow: none;
    border-color: #d9d9d9;
  }
}
input.ant-input-focused {
  box-shadow: none;
  border-color: #d9d9d9;
}
div.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border-color: #d9d9d9;
  
  :focus,:hover {
    box-shadow: none;
    border-color: #d9d9d9;
  }
}
div.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #d9d9d9;
} */

// custom pagination
/* li.ant-pagination-item-active {
  background: #041527;
  border-color: #041527;
  
  a {
    color: #fff;
  }

}
.ant-pagination-item-active:focus, .ant-pagination-item-active:hover {
  border-color: #041527;
}
li.ant-pagination-item:focus, li.ant-pagination-item:hover {
  background: #041527;
  border-color: #041527;
  a {
    color: #fff;
  }
} */

.arrow_box {
  background: rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  width: 140px;
  height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #fff;
}

.custom-popover {
  left: 16px !important;
  .ant-popover-inner {
    /* border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 8px rgba(0,0,0,0.08); */
    border-radius: 8px;
  }

  .ant-popover-inner-content {
    padding: 16px 0;
  }
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

.search-bar.ant-input-affix-wrapper input {
  font-size: 13px;
}

.react-icon {
  font-size: 24px;
  vertical-align: middle;
}

.react-icon-small {
  font-size: 20px;
  vertical-align: middle;
}

.react-icon-right {
  font-size: 24px;
  vertical-align: middle;
  margin-left: 10px;
}

.react-icon-right-small {
  font-size: 20px;
  vertical-align: middle;
  margin-left: 10px;
}

.react-icon-left {
  font-size: 24px;
  vertical-align: middle;
  margin-right: 10px;
}

.react-icon-left-small {
  font-size: 20px;
  vertical-align: middle;
  margin-right: 10px;
}

ul.ant-menu-horizontal {
  border-bottom: 0;
}

ul.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
  margin-left: 0;
  padding: 0 12px;
  border-bottom: 1px solid #f0f0f0;
}

ul.ant-menu-horizontal > .ant-menu-item::after, ul.ant-menu-horizontal > .ant-menu-submenu::after {
  right: 8px;
  left: 8px;
}

/* button.ant-btn {
  height: 36px;
} */

.ant-table-empty .ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: #fff !important;
}

.ant-menu-inline-collapsed-tooltip .icon {
  display: none;
}

.scrollable-table {
  .ant-table-body {
    overflow-y: auto !important;

    &::-webkit-scrollbar {
      height: 6px;
      width: 6px;
      background: transparent;
    }
    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #69696999;
      border-radius: 50px;
    }
  }
}

ul.ant-pagination.ant-pagination-disabled {
  .ant-pagination-item, .ant-pagination-item-link {
    background: #fff;
  }
} 


`;

export default GlobalStyles;
