import {combineReducers} from 'redux';
import {EkycTypes} from '../../action_types';

import {handleRequest, initialRequestState} from './helpers';

function createSession(state = initialRequestState(), action) {
  return handleRequest(
    EkycTypes.CREATE_SESSION_REQUEST,
    EkycTypes.CREATE_SESSION_SUCCESS,
    EkycTypes.CREATE_SESSION_FAIL,
    state,
    action
  );
}

function uploadFront(state = initialRequestState(), action) {
  return handleRequest(
    EkycTypes.UPLOAD_FRONT_REQUEST,
    EkycTypes.UPLOAD_FRONT_SUCCESS,
    EkycTypes.UPLOAD_FRONT_FAIL,
    state,
    action
  );
}

function uploadBack(state = initialRequestState(), action) {
  return handleRequest(
    EkycTypes.UPLOAD_BACK_REQUEST,
    EkycTypes.UPLOAD_BACK_SUCCESS,
    EkycTypes.UPLOAD_BACK_FAIL,
    state,
    action
  );
}

function uploadUserImage(state = initialRequestState(), action) {
  return handleRequest(
    EkycTypes.UPLOAD_USER_IMAGE_REQUEST,
    EkycTypes.UPLOAD_USER_IMAGE_SUCCESS,
    EkycTypes.UPLOAD_USER_IMAGE_FAIL,
    state,
    action
  );
}

function getUserImage(state = initialRequestState(), action) {
  return handleRequest(
    EkycTypes.GET_USER_IMAGE_REQUEST,
    EkycTypes.GET_USER_IMAGE_SUCCESS,
    EkycTypes.GET_USER_IMAGE_FAIL,
    state,
    action
  );
}

function getDocumentImage(state = initialRequestState(), action) {
  return handleRequest(
    EkycTypes.GET_DOCUMENT_IMAGE_REQUEST,
    EkycTypes.GET_DOCUMENT_IMAGE_SUCCESS,
    EkycTypes.GET_DOCUMENT_IMAGE_FAIL,
    state,
    action
  );
}

function validateRecord(state = initialRequestState(), action) {
  return handleRequest(
    EkycTypes.VALIDATE_RECORD_REQUEST,
    EkycTypes.VALIDATE_RECORD_SUCCESS,
    EkycTypes.VALIDATE_RECORD_FAIL,
    state,
    action
  );
}

export default combineReducers({
  createSession,
  uploadFront,
  uploadBack,
  uploadUserImage,
  getUserImage,
  getDocumentImage,
  validateRecord,
});
