import {combineReducers} from 'redux';
import {EkycTypes} from '../../action_types';
import {arrayToObject, removeItem, insertItem, updateObjectInArray} from '@app/utils/helpers';

function session(state = {}, action) {
  switch (action.type) {
    case EkycTypes.CREATE_SESSION_SUCCESS:
    case EkycTypes.UPLOAD_FRONT_SUCCESS:
    case EkycTypes.UPLOAD_BACK_SUCCESS:
    case EkycTypes.UPLOAD_USER_IMAGE_SUCCESS:
      return action.data;
    case EkycTypes.CLEAR_DATA_EKYC:
    case EkycTypes.CLEAR_SESSION:
      return {};
    default:
      return state;
  }
}

function document_images(state = {}, action) {
  switch (action.type) {
    case EkycTypes.GET_DOCUMENT_IMAGE_SUCCESS:
      return {...state, [action.id]: action.data};
    default:
      return state;
  }
}

function user_images(state = {}, action) {
  switch (action.type) {
    case EkycTypes.GET_USER_IMAGE_SUCCESS:
      return {...state, [action.id]: action.data};
    default:
      return state;
  }
}

function params(state = {}, action) {
  switch (action.type) {
    case EkycTypes.SET_PARAMS_EKYC:
      return action.data;
    case EkycTypes.CLEAR_DATA_EKYC:
      return {};
    default:
      return state;
  }
}

export default combineReducers({
  session,
  document_images,
  user_images,
  params,
});
