export const PUBLIC_ROUTE = {
  LANDING: '/',
  GKS: '/gks',
  STEP_ONE_DOT_ONE: '/step-one-dot-one',
  STEP_ONE_DOT_TWO: '/step-one-dot-two',
  STEP_TWO: '/step-two',
  STEP_THREE: '/step-three',
  SUCCESSFUL_RESULT: '/success',
  ERROR_RESULT: '/error',
  WELCOME: '/welcome',
};
