export default {
  CREATE_SESSION_REQUEST: 'CREATE_SESSION_REQUEST',
  CREATE_SESSION_SUCCESS: 'CREATE_SESSION_SUCCESS',
  CREATE_SESSION_FAIL: 'CREATE_SESSION_FAIL',

  UPLOAD_FRONT_REQUEST: 'UPLOAD_FRONT_REQUEST',
  UPLOAD_FRONT_SUCCESS: 'UPLOAD_FRONT_SUCCESS',
  UPLOAD_FRONT_FAIL: 'UPLOAD_FRONT_FAIL',

  UPLOAD_BACK_REQUEST: 'UPLOAD_BACK_REQUEST',
  UPLOAD_BACK_SUCCESS: 'UPLOAD_BACK_SUCCESS',
  UPLOAD_BACK_FAIL: 'UPLOAD_BACK_FAIL',

  UPLOAD_USER_IMAGE_REQUEST: 'UPLOAD_USER_IMAGE_REQUEST',
  UPLOAD_USER_IMAGE_SUCCESS: 'UPLOAD_USER_IMAGE_SUCCESS',
  UPLOAD_USER_IMAGE_FAIL: 'UPLOAD_USER_IMAGE_FAIL',

  GET_USER_IMAGE_REQUEST: 'GET_USER_IMAGE_REQUEST',
  GET_USER_IMAGE_SUCCESS: 'GET_USER_IMAGE_SUCCESS',
  GET_USER_IMAGE_FAIL: 'GET_USER_IMAGE_FAIL',

  GET_DOCUMENT_IMAGE_REQUEST: 'GET_DOCUMENT_IMAGE_REQUEST',
  GET_DOCUMENT_IMAGE_SUCCESS: 'GET_DOCUMENT_IMAGE_SUCCESS',
  GET_DOCUMENT_IMAGE_FAIL: 'GET_DOCUMENT_IMAGE_FAIL',

  GET_RECORD_REQUEST: 'GET_RECORD_REQUEST',
  GET_RECORD_SUCCESS: 'GET_RECORD_SUCCESS',
  GET_RECORD_FAIL: 'GET_RECORD_FAIL',

  UPDATE_RECORD_REQUEST: 'UPDATE_RECORD_REQUEST',
  UPDATE_RECORD_SUCCESS: 'UPDATE_RECORD_SUCCESS',
  UPDATE_RECORD_FAIL: 'UPDATE_RECORD_FAIL',

  VALIDATE_RECORD_REQUEST: 'VALIDATE_RECORD_REQUEST',
  VALIDATE_RECORD_SUCCESS: 'VALIDATE_RECORD_SUCCESS',
  VALIDATE_RECORD_FAIL: 'VALIDATE_RECORD_FAIL',

  SET_PARAMS_EKYC: 'SET_PARAMS_EKYC',

  CLEAR_DATA_EKYC: 'CLEAR_DATA_EKYC',

  CLEAR_SESSION: 'CLEAR_SESSION',
};
